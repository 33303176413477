var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wishlist-chooselist" },
    [
      _c(
        "ul",
        { staticClass: "wishlist-list" },
        _vm._l(_vm.lists, function (list) {
          return _c(
            "li",
            {
              key: list.id_wishlist,
              staticClass: "wishlist-list-item",
              on: {
                click: function ($event) {
                  return _vm.select(list.id_wishlist)
                },
              },
            },
            [_c("p", [_vm._v("\n        " + _vm._s(list.name) + "\n      ")])]
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.$apollo.queries.lists.loading
        ? _c(
            "ContentLoader",
            { staticClass: "wishlist-list-loader", attrs: { height: "105" } },
            [
              _c("rect", {
                attrs: {
                  x: "0",
                  y: "12",
                  rx: "3",
                  ry: "0",
                  width: "100%",
                  height: "11",
                },
              }),
              _vm._v(" "),
              _c("rect", {
                attrs: {
                  x: "0",
                  y: "36",
                  rx: "3",
                  ry: "0",
                  width: "100%",
                  height: "11",
                },
              }),
              _vm._v(" "),
              _c("rect", {
                attrs: {
                  x: "0",
                  y: "60",
                  rx: "3",
                  ry: "0",
                  width: "100%",
                  height: "11",
                },
              }),
              _vm._v(" "),
              _c("rect", {
                attrs: {
                  x: "0",
                  y: "84",
                  rx: "3",
                  ry: "0",
                  width: "100%",
                  height: "11",
                },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.lists && _vm.lists.length <= 0 && !_vm.$apollo.queries.lists.loading
        ? _c("p", { staticClass: "wishlist-list-empty" }, [
            _vm._v("\n    " + _vm._s(_vm.emptyText) + "\n  "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }