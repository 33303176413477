var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "wishlist-toast",
      class: [{ isActive: _vm.active }, _vm.type],
    },
    [
      _c("p", { staticClass: "wishlist-toast-text" }, [
        _vm._v("\n    " + _vm._s(_vm.text) + "\n  "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }