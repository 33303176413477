var render, staticRenderFns
import script from "./AddToWishlist.vue?vue&type=script&lang=js"
export * from "./AddToWishlist.vue?vue&type=script&lang=js"
import style0 from "./AddToWishlist.vue?vue&type=style&index=0&id=cab62222&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cab62222",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/dev.ht26.com/modules/blockwishlist/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('cab62222')) {
      api.createRecord('cab62222', component.options)
    } else {
      api.reload('cab62222', component.options)
    }
    
  }
}
component.options.__file = "_dev/front/js/components/AddToWishlist/AddToWishlist.vue"
export default component.exports